import './App.css';
import cv from './LucasGomez-Resume-2024.pdf'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';



const Contact = () => (
    <div className='contact'
        style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: 'black',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            backgroundPepeat: 'noRepeat',
        }}>
        <Breadcrumbs aria-label="breadcrumb" style={{color:'white', display: 'flex', justifyContent: 'left', backgroundColor: 'transparent', transform: 'translateX(3%)' }}>
            <Link component={RLink} style={{ color: 'white', fontSize: 40 }} to="/">
                Home
            </Link>
            <Link component={RLink} style={{ color: 'white', fontSize: 30 }} to='/about' >
                About me
            </Link>
            <Link component={RLink} style={{ color: 'white', fontSize: 30 }} to='/projects' >
                Projects
            </Link>
            <Link style={{ color: 'white', fontSize: 30 }} target="_blank" href={'https://medium.com/@lucasmgomez'} >
                Blogs
            </Link>	
            <Link style={{ color: 'white', fontSize: 30 }} target="_blank" href={cv} >
                Resume
            </Link>
            <Link style={{ color: 'white', fontSize: 30 }} target="_blank" href={'https://github.com/lucasmgomez'} >
                Github
            </Link>
        </Breadcrumbs>
        <h1 style={{ color: 'white', fontSize: 40, textAlign: 'center', padding: '35px'}} >Where to find me...</h1>
        <p style={{fontSize:'20px', color: 'white', textAlign: 'center', padding: '3px'}}>Mila or McGill University, Montreal, Canada</p>
        <p style={{fontSize:'20px', color: 'white',  textAlign: 'center', padding: '3px'}}> lucas.gomez@mila.quebec </p>
        
    </div>
);

export default Contact;