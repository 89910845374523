import './App.css';
import cv from './LucasGomez-Resume-2024.pdf'
import thesis from './Thesis.pdf'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const Projects = () => (
    <div className='projects'
        style={{
            overflowY: 'scroll',
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: 'black',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            backgroundPepeat: 'noRepeat',
        }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white', display: 'flex', justifyContent: 'left', backgroundColor: 'transparent', transform: 'translateX(3%)' }}>
            <Link component={RLink} style={{ color: 'white', fontSize: 40 }} to="/">
                Home
            </Link>
            <Link component={RLink} style={{ color: 'white', fontSize: 30 }} to="/about">
                About me
            </Link>
            <Link style={{ color: 'white', fontSize: 30 }} target="_blank" href={'https://medium.com/@lucasmgomez'} >
                Blogs
            </Link>	
            <Link style={{ color: 'white', fontSize: 30 }} target="_blank" href={cv} >
                Resume
            </Link>
            <Link style={{ color: 'white', fontSize: 30 }} target="_blank" href={'https://github.com/lucasmgomez'} >
                Github
            </Link>
            <Link component={RLink} style={{ color: 'white', fontSize: 30 }} to="/contact">
                Contact me
            </Link>
        </Breadcrumbs>
        <h1 style={{ color: 'white', fontSize: 40, textAlign: 'center', paddingTop: '159px', paddingBottom: '30px' }} >Deep Convolutional Spiking Neural Networks and The Human Visual System - <a style={{ color: 'white' }}
            href='https://github.com/lucasmgomez/DSCNN-DCNN-RSA' target="_blank">An Honors Thesis</a></h1>
        <p style={{ color: 'white', justifyContent: 'center', textAlign: 'center', padding: '3px', margin: 'auto', width: '90%' }}>
            For my undergraduate thesis I set out to compare the neural similarity scores of spiking and non-spiking object recognition convolutional neural networks. If you want to know more check out the <a href={thesis}>paper</a>.
        </p>
        <h1 style={{ color: 'white', fontSize: 40, textAlign: 'center', paddingTop: '159px', paddingBottom: '30px' }} >Neuromatch Academy 2022 - <a style={{ color: 'white' }}
            href='https://github.com/lucasmgomez/NMA-fMRI-Project.git' target="_blank">Group fMRI Project</a></h1>
        <p style={{ color: 'white', justifyContent: 'center', textAlign: 'center', padding: '3px', margin: 'auto', width: '90%' }}>
            Prediction of semantically differentiated tasks from fMRI using the standard GLM and Time-Domain Decoding Model. For more info check out my <a href='https://medium.com/@saculzemog/neuromatch-academy-2022-computational-neuroscience-153fd043e021'>blog</a>.
        </p>
        <h1 style={{ color: 'white', fontSize: 40, textAlign: 'center', paddingTop: '159px', paddingBottom: '30px' }} >Large Language Models for Ai-Da </h1>
        <p style={{ color: 'white', justifyContent: 'center', textAlign: 'center', padding: '3px', margin: 'auto', width: '90%' }}>
            Applied and finetuned various models for artistically inspired text generation for the ongoing AI art 
            project <a href='https://www.ai-darobot.com/' target="_blank">Ai-Da</a>.
        </p>
        <p style={{ color: 'white', justifyContent: 'center', textAlign: 'center', paddingTop: '20px', paddingBottom: '20px', margin: 'auto', width: '90%' }}>
            One very exciting opportunity i've had working on the Ai-Da project was being able to produce custom lyrics for the band The 1975. Using my generated 
            texts from lyrical prompts they made the following song and music video...
        </p>
        <div style={{
            textAlign: 'center',
            width: '100%'
        }} >
            <iframe style={{ justifyContent: 'center', }} width="560" height="315" src="https://www.youtube.com/embed/dTK9N7n8Wcg"
                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>

        <h1 style={{ color: 'white', fontSize: 40, textAlign: 'center', paddingTop: '135px', paddingBottom: '30px' }} > S&P500 Time series Forcasting </h1>
        <p style={{ color: 'white', justifyContent: 'center', textAlign: 'center', padding: '3px', margin: 'auto', width: '90%' }}>
            An attempt to apply Deep learning Time Series Forcasting on major market indicators to predict the S&P500.
        </p>

        <h1 style={{ color: 'white', fontSize: 25, textAlign: 'center', paddingTop: '45px', paddingBottom: '30px' }} > LSTM SPY Time Series Model:  <a style={{ color: 'white' }}
            href='https://github.com/lucasmgomez/SP500-TimeSeries-Forcasting/tree/master/Version%203' target="_blank">Github</a> </h1>
        <p style={{ color: 'white', justifyContent: 'center', textAlign: 'center', padding: '3px', margin: 'auto', width: '90%' }}>
            This is the latest version of the project. I trained an LSTMs to predict the S&P500 ETF SPY using 8 market indicators including: the MACD, SOMA, and VIX.
            The models achieved accuracies of 60% for predicting the SPY weekly average 14 days and 30 days into the future.
        </p>

    </div>
);

export default Projects;